




export const PART_API_BASE_URLS = {  
  
  GetListProducts : "/api/Product/externe-get-list-products",
    //orders: 'https://api.example.com/orders',
    //users: 'https://api.example.com/users',
    // Ajoutez d'autres URLs d'API ici
  };